
  import AppTitle from "@/components/app-title.vue";
  export default {
    name: 'IndexPage',
    components: {
      AppTitle
    },
    data() {
      return {
        cateList: [{
            key: "square",
            selected: true,
            name: "不锈钢消防水箱",
          },
          {
            key: "warm",
            name: "不锈钢保温水箱",
          },
          {
            key: "life",
            name: "不锈钢生活水箱",
          },
          {
            key: "ic",
            name: "巧克力水箱",
          },
          {
            key: "clean",
            name: "不锈钢承压水箱",
          },
          {
            key: "bdf",
            name: "BDF水箱",
          },
          {
            key: "land",
            name: "地埋式水箱",
          },
          {
            key: "circle",
            name: "圆柱形水箱",
          },
          {
            key: "space",
            name: "油水分离器",
          },
          {
            key: "room",
            name: "一体化智慧泵房",
          },
          {
            key: "clear",
            name: "水箱消毒器",
          },
          {
            key: "lift",
            name: "污水提升设备",
          },
          {
            key: "provide",
            name: "无负压供水设备",
          }
        ],
        productList: [],
        //优秀案例
        advantageList: [],
        productTmp: {},

        partList: [
          "partner_1",
          "partner_2",
          "partner_3",
          "partner_4",
          "partner_5",
          "partner_6",
          "partner_7",
          "partner_8"
        ],
        newsList:[],
        desc:""
      }
    },
    async asyncData(context) {
      let productData = await context.$request.productMgr.getList("square");
      let newsData=await context.$request.newsMgr.getList();

      let newsList=newsData.list.sort(function(a,b){
        if(a.date){
           return Date.parse(a.date.replace(/-/g,"/")) > Date.parse(b.date.replace(/-/g,"/")) ?-1:1;
        }
      });


      let advantageList = [];
      for (var i = 0; i < 8; i++) {
        advantageList.push({
          "img": `index_goods_${i+1}`,
        });
      }

       return {
         productList: productData.list,
         advantageList: advantageList,
         newsList:newsList.slice(0,2),
         desc:productData.desc || ""
       }
    },
    mounted() {
      //this.buidDB();
      this.goScroll({
        scroll_begin: document.querySelector(".scroll_begin"),
        scroll_end: document.querySelector(".scroll_end"),
        scroll_div: document.querySelector(".scroll_div"),
      });
    },
    methods: {
      onCate(item) {
        for (let cate of this.cateList) {
          this.$set(cate, "selected", false);
        }
        this.$set(item, "selected", true);

        if (!this.productTmp[item.key]) {
          this.getProduct(item.key);
        } else {
          this.productList = this.productTmp[item.key].list || [];
          this.desc=this.toHtml(this.productTmp[item.key].desc || "");
        }
      },
      getProduct(key) {
        this.$request.productMgr.getList(key).then((result) => {
          this.productList = result.list || [];
          this.desc=this.toHtml(result.desc || "");
          this.productTmp[key] = result || {};
        });
      },
      toHtml(str) {
          var arrEntities = {
            'lt': '<',
            'gt': '>',
            'nbsp': ' ',
            'amp': '&',
            'quot': '"'
          };
          return str.replace(/&(lt|gt|nbsp|amp|quot);/ig,
            function(all, t) {
              return arrEntities[t];
            });
      },
      goScroll(obj) {
        var self = this;
        var speed = 10;
        var scroll_begin = obj['scroll_begin'];
        var scroll_end = obj['scroll_end'];
        var scroll_div = obj['scroll_div'];

        if (!self.MyMar) {
          self.MyMar = setInterval(Marquee, speed);
        }

        function Marquee() {
          if (scroll_end.offsetWidth - scroll_div.scrollLeft <= 0) {
            scroll_div.scrollLeft -= scroll_begin.offsetWidth;
          } else {
            scroll_div.scrollLeft++;
          }
        }

        scroll_div.onmouseover = function() {
          clearInterval(self.MyMar);
        }
        scroll_div.onmouseout = function() {
          self.MyMar = setInterval(Marquee, speed);
        }
      },
      canvas() {
        let width = 500;
        let height = 200;
        let canvas = document.createElement("canvas");
        canvas.width = width;
        canvas.height = height;
        let ctx = canvas.getContext("2d");

        var catchImgMap = window.localStorage.getItem("imgMap") ? JSON.parse(window.localStorage.getItem(
          "imgMap")) : {};
        for (let item of this.list) {
          var cacheImg = catchImgMap[item.img];
          if (cacheImg) {
            item.src = cacheImg;
          } else {
            let img = new Image();
            img.crossOrigin = 'Anonymous';
            img.src = item.img;
            img.onload = (e) => {
              ctx.clearRect(0, 0, width, height);
              ctx.drawImage(img, 0, 0, width, height);
              catchImgMap[item.img] = canvas.toDataURL("image/jpg", 1)
              item.src = catchImgMap[item.img];
              window.localStorage.setItem("imgMap", JSON.stringify(catchImgMap));
            }
          }
        }
      },
      buidDB() {
        var self = this;
        //1.打开/创建一个 IndexedDB 数据库（当该数据库不存在时，open 方法会直接创建一个名为 xiaoceDB 新数据库）。
        // 后面的回调中，我们可以通过event.target.result拿到数据库实例
        let db = window.indexedDB || window.webkitIndexedDB || window.mozIndexedDB || window.msIndexedDB;
        // 参数1位数据库名，参数2为版本号
        const request = window.indexedDB.open("hh", 1)
        // 使用IndexedDB失败时的监听函数
        request.onerror = function(event) {
          console.log('无法使用IndexedDB')
        }


        //2.创建一个 object store（object store 对标到数据库中的“表”单位）。
        // onupgradeneeded事件会在初始化数据库/版本发生更新时被调用，我们在它的监听函数中创建object store
        request.onupgradeneeded = function(event) {
          //  db = event.target.result
          // let objectStore
          // // 如果同名表未被创建过，则新建test表
          // if (!db.objectStoreNames.contains('test')) {
          //   objectStore = db.createObjectStore('test', { keyPath: 'id' })
          // }
          db = event.target.result;
          var objectStore = db.createObjectStore('person', {
            keyPath: 'id'
          });
          objectStore.createIndex('name', 'name', {
            unique: false
          });
          objectStore.createIndex('email', 'email', {
            unique: true
          });

        }




        // 成功
        request.onsuccess = function(event) {
          // 此处就可以获取到db实例
          db = event.target.result
          console.log("你打开了IndexedDB")


          // 新增数据

          self.add(db);


          function read() {
            //读取数据
            var transaction = db.transaction(['person']);
            var objectStore = transaction.objectStore('person');
            var request = objectStore.get(2);
            request.onerror = function(event) {
              console.log('事务失败');
            };
            request.onsuccess = function(event) {
              if (request.result) {
                console.log("读取数据", request)
                // console.log('Name: ' + request.result.name);
                // console.log('Age: ' + request.result.age);
                // console.log('Email: ' + request.result.email);
              } else {
                console.log('未获得数据记录');
              }
            };
          }
          read();


          //遍历数据
          function readAll() {
            var objectStore = db.transaction('person').objectStore('person');
            objectStore.openCursor().onsuccess = function(event) {
              var cursor = event.target.result;
              if (cursor) {
                console.log('Id: ' + cursor.key);
                console.log('Name: ' + cursor.value.name);
                console.log('Age: ' + cursor.value.age);
                console.log('Email: ' + cursor.value.email);
                cursor.continue();
              } else {
                console.log('没有更多数据了！');
              }
            };
          }
          readAll();


          //更新数据
          function update() {
            var request = db.transaction(['person'], 'readwrite')
              .objectStore('person')
              .put({
                id: 1,
                name: '李四',
                age: 35,
                email: 'lisi@example.com'
              });
            request.onsuccess = function(event) {
              console.log('数据更新成功');
            };
            request.onerror = function(event) {
              console.log('数据更新失败');
            }
          }
          update();



          //3.构建一个事务来执行一些数据库操作，像增加或提取数据等。
          // 创建事务，指定表格名称和读写权限
          /* const transaction = db.transaction(["test"],"readwrite")
          // 拿到Object Store对象
          const objectStore = transaction.objectStore("test")
          // 向表格写入数据
          objectStore.add({id: 1, name: 'xiuyan'}) */

          //4.通过监听正确类型的事件以等待操作完成。
          // 操作成功时的监听函数
          // transaction.oncomplete = function(event) {
          //   console.log("操作成功")
          // }
          // // 操作失败时的监听函数
          // transaction.onerror = function(event) {
          //   console.log("这里有一个Error")
          // }



        }
      },
      add(db) {
        var request = db.transaction(['person'], 'readwrite')
          .objectStore('person')
          .put({
            id: 1,
            name: '张三',
            age: 24,
            email: 'zhangsan@example.com'
          })
        //.add
        request.onsuccess = function(event) {
          console.log('数据写入成功');
        };
        request.onerror = function(event) {
          console.log('数据写入失败');
        }
      }
    },
  }
