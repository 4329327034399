import { render, staticRenderFns } from "./index.vue?vue&type=template&id=7703dd32&scoped=true&"
import script from "./index.vue?vue&type=script&lang=js&"
export * from "./index.vue?vue&type=script&lang=js&"
import style0 from "./index.vue?vue&type=style&index=0&id=7703dd32&prod&lang=less&scoped=true&"


/* normalize component */
import normalizer from "!../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "7703dd32",
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {AppSwiper: require('/www/wwwroot/www.hankahuanbao.com/components/app-swiper.vue').default,AppTitle: require('/www/wwwroot/www.hankahuanbao.com/components/app-title.vue').default,AppImage: require('/www/wwwroot/www.hankahuanbao.com/components/app-image.vue').default})
